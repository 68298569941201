var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list-panel"},[_c('div',{staticClass:"switch-button-group"},[_c('div',{class:[
            'switch-button',
            {
              'is-active': _vm.mode === 'left',
            }
        ]},[_c('span',{staticClass:"fas fa-list"})]),_c('div',{class:[
             'switch-button',
             {
               'is-active': _vm.mode === 'right',
             }
        ]},[_c('span',{staticClass:"fas fa-user"})])]),_c('div',{class:[
      'content layout-horizontal ',
      {
        'animated': _vm.isAnimated,
      },
      'mode__' + _vm.mode ],staticStyle:{"height":"100%"},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"sub-panel-left",staticStyle:{"overflow":"hidden"}},[_c('user-list',{ref:"userList",staticStyle:{"width":"100%","height":"100%"},attrs:{"organization-id":_vm.organizationId},on:{"change":_vm.handleUserChange}})],1),_c('div',{staticClass:"sub-panel-right"},[_c('user-info',{staticStyle:{"width":"100%","height":"100%"},attrs:{"organization-id":_vm.organizationId,"value":_vm.user},on:{"accountchange":_vm.handleAccountChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }