<template>
  <ys-infinite-scroll-view
      class="user-list"
      @loadmore="loadMore"
  >
    <div class="divider"></div>
    <div
        v-for="user in userList"
        :key="user.id">
      <user-list-item
          :value="user"
          class="user-list-item"
          @click.native.stop="clickUser(user)"
          :is-active="currentUser !== null && user.id === currentUser.id"
      ></user-list-item>
      <div class="divider"></div>
    </div>
  </ys-infinite-scroll-view>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {SyntheticUserGroupIds} from "@/assets/javascript/user-group-utils";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import UserListItem from "@/pages/user/panel/UserListItem";

export default {
  name: "UserList",
  components: {UserListItem, YsInfiniteScrollView},
  mixins: [httpapi],
  props: {
    organizationId: Number,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 查询条件
       */
      accountId: null,
      groupId: SyntheticUserGroupIds.ALL,
      search: null,

      /**
       * 用户列表
       */
      userList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      currentUser: null,

    }
  },
  computed: {
    inputParams: function () {
      const {organizationId, accountId, groupId, search} = this;
      return {
        organizationId,
        accountId,
        groupId,
        search,
      }
    },
  },
  watch: {
    organizationId: {
      handler() {
        this.currentUser = null;
      },
      immediate: true,
    },
    inputParams: {
      handler() {
        this.loadingCode++;
        this.loadUsers();
      },
      immediate: true,
    },
  },
  mounted() {
    window.eventBus.$on('notify:message', this.handleConsultancyMessage);
    window.eventBus.$on('update:user_remark', this.handleUserRemarkUpdate)
  },
  destroyed() {
    window.eventBus.$off('update:user_remark', this.handleUserRemarkUpdate);
  },
  methods: {
    clickUser: function (user) {
      this.currentUser = user;
      this.$emit('change', {user})
    },
    setGroupId: function (groupId) {
      this.groupId = groupId;
      this.accountId = null;
      this.search = null;
    },
    setAccountId: function (accountId) {
      this.accountId = accountId;
    },
    setSearch: function (search) {
      this.search = search;
      this.accountId = null;
    },
    loadUsers: function (pageNum = 1) {
      if (!this.organizationId) return;
      if (this.accountId != null) {
        this.loadUsersByAccount(this.accountId, pageNum);
      } else if (this.search) {
        this.searchUsers(this.search, pageNum);
      } else if (this.groupId != null) {
        this.loadUsersByGroup(this.groupId, pageNum);
      }
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) {
        this.loadUsers(this.pageNum + 1);
      }
    },
    handleConsultancyMessage: function () {
      if (this.groupId === SyntheticUserGroupIds.UNCLOSED_CONSULTANCY) {
        this.loadUsers();
      }
    },
    handleUserRemarkUpdate(userRemark) {
      let user = null;
      for (let tmp of this.userList) {
        if (tmp.user.id === userRemark.userId) {
          user = tmp;
          break;
        }
      }
      if (user) {
        this.loadUser(user.id);
      }
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadUsersByAccount: function (accountId, pageNum) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/user/account/users/${accountId}/${this.organizationId}/${pageNum}/${this.pageSize}`,
      })
          .returnWhenCodeMatches()
          .then(this.loadSuccess)
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },
    loadUsersByGroup: function (groupId, pageNum) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/user/group/users/${groupId}/${this.organizationId}/${pageNum}/${this.pageSize}`,
      })
          .returnWhenCodeMatches()
          .then(this.loadSuccess)
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    searchUsers: function (search, pageNum) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/user/list/${this.organizationId}/${pageNum}/${this.pageSize}/${search}`
      })
          .returnWhenCodeMatches()
          .then(this.loadSuccess)
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    loadSuccess: function (res) {
      let userList = res.data.pageNum === 1 ? [] : this.userList;
      this.$appendAfter(userList, res.data.list);
      this.userList = userList;
      this.pageNum = res.data.pageNum;
      this.totalPages = res.data.pages;
    },
    loadUser: function (userId) {
      this.$reqGet({
        path: `/user/item/${userId}/${this.organizationId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let user = res.data;
            for (let n = 0; n < this.userList.length; n++) {
              if (this.userList[n].user.id === user.id) {
                this.userList.splice(n, 1, user);
                break;
              }
            }
            if (this.currentUser?.user.id === user.user.id) {
              this.currentUser = user;
              console.log('current user: ' + JSON.stringify(user));
              this.$emit('change', {user});
            }
          })
          .catch(() => this.$message.error('加载失败'));
    }
  }
}
</script>

<style scoped>

.user-list {
  background-color: #f1f1f1;
  height: inherit;
}

.user-list-item {
  width: 100%;
}

</style>