<template>
  <div>
    <div class="margin-bottom font-size-medium font-weight-bold" v-if="user.message"><span class="fas fa-quote-right font-color-light-placeholder"></span> {{user.message}}</div>
    <div v-if="procedure">
      <div class="font-size-large layout-horizontal layout-middle">
        <ys-button lighting @click="clickMgmt" size="small">
          <template v-if="$route.name === 'user_procedure_mgmt'">
            <span class="fas fa-chevron-left"></span>
            <span class="margin-left-small">返回</span>
          </template>
          <template v-else>
            <span class="fas fa-pen-alt"></span>
            <span class="margin-left-small">管理流程</span>
          </template>
        </ys-button>
        <span class="margin-left">{{ procedure.scheme.title }}</span>
        <span v-if="procedure.currentStage.state !== ProcedureState.END"> · 第{{ days + 1 }}天</span>
      </div>
      <div style="max-width: 100%; overflow: hidden">
        <div class="procedure padding-top-large font-size-small">
          <div
              :class="[
            'procedure-stage',
            {
              'timeline__passed': stage.timeline < 0,
              'timeline__current': stage.timeline === 0,
              'timeline__future': stage.timeline > 0
            }
            ]"
              v-for="(stage, index) in procedure.procedureStages"
              :key="stage.globalId"
              v-show="index >= currentStageIndex - 5"
          >
            <div class="dot before"></div>
            <div class="dot before"></div>
            <div class="font-align-center">
              <div class="current-stage-arrow" v-if="stage.timeline === 0"><span class="fas fa-arrow-down"></span></div>
              <div class="state-name"
                   @click="clickProcedureStage(stage)"
              >{{ ProcedureUtils.getStateName(stage.state) }}
              </div>
            </div>
            <div class="dot after"></div>
            <div class="dot after"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="font-color-placeholder font-size-large layout-horizontal layout-middle">
      <ys-button lighting @click="clickMgmt">
        <span class="fas fa-pen-alt"></span>
        <span class="margin-left-small">管理流程</span>
      </ys-button>
      <div class="margin-left-small">该用户未开始干预</div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {ProcedureState, ProcedureUtils} from "@/assets/javascript/procedure-utils";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "Procedure",
  components: {YsButton},
  mixins: [httpapi],
  props: {
    user: Object,
    organizationId: Number,
  },
  data() {
    return {
      /**
       * 常量
       */
      ProcedureUtils,
      ProcedureState,

      /**
       * 忙态
       */
      busyLoadingCode: 0,

      /**
       * 干预流程
       */
      procedure: null,

      /**
       * 当前阶段索引
       */
      currentStageIndex: 0,

      /**
       * 干预天数
       */
      days: null,

      /**
       * 用户id
       */
      userId: null,
    }
  },
  computed: {
    inputParams: function () {
      const {user, organizationId} = this;
      return {
        user,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.procedure = null;
        this.userId = this.user?.user.id;
        this.loadUserProcedure();
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('notify:procedure', this.loadUserProcedure);
  },
  destroyed() {
    window.eventBus.$off('notify:procedure', this.loadUserProcedure);
  },
  methods: {
    clickMgmt: function () {
      if (this.$route.name === 'user_procedure_mgmt') {
        this.$router.back();
      } else {
        this.$router.push({name: 'user_procedure_mgmt'})
      }
    },
    clickProcedureStage: function (stage) {
      switch (stage.state) {
        case ProcedureState.INVESTIGATION:
        case ProcedureState.ESTIMATE:
          this.$router.push({name: 'user_questionnaire_mgmt'});
          break;
        case ProcedureState.MAKE_PRESCRIPTION:
        case ProcedureState.ADJUST:
          this.$router.push({name: 'user_prescription_mgmt'});
          break;
        case ProcedureState.PUNCH:
          this.$router.push({name: 'user_punch_mgmt'});
          break;
      }
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadUserProcedure: function (event) {
      if (event) {
        console.log('event: ' + event.userId);
        if (event.userId != this.userId) {
          return;
        }
      }
      if (this.isBusy()) return;
      this.$reqGet({
        path: `/procedure/${this.userId}/${this.organizationId}`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            let procedure = res.data;
            if (procedure) {
              ProcedureUtils.insertFutureStages(procedure.procedureStages);
              this.days = TimeUtils.daysBetween(procedure.startTime, new Date());
              for(let index = 0; index < procedure.procedureStages.length; index ++) {
                if(procedure.procedureStages[index].timeline === 0) {
                  this.currentStageIndex = index;
                  break;
                }
              }
            }
            this.procedure = res.data;
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => this.busyLoadingCode = 0);
    }
  }
}
</script>

<style scoped>

.procedure {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}

.procedure-stage {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}

.procedure-stage .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  background-color: #dadada;
}

.procedure-stage.timeline__passed .dot {
  background-color: #f6bbc4;
}

.procedure-stage.timeline__current .dot.before {
  background-color: #f6bbc4;
}

.procedure-stage .state-name {
  padding: 2px 6px;
  border-radius: 100px;
  background-color: #dadada;
  color: #909399;
  cursor: pointer;
}

.procedure-stage .state-name:hover {
  opacity: .7;
}

.procedure-stage.timeline__passed .state-name {
  background-color: #f6bbc4;
  color: white;
}

.procedure-stage.timeline__current .state-name {
  background-color: #f091a6;
  color: white;
}

.current-stage-arrow {
  height: 0px;
  position: relative;
  color: #f091a6;
  top: -17px;
}

</style>