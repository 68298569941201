var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user.message)?_c('div',{staticClass:"margin-bottom font-size-medium font-weight-bold"},[_c('span',{staticClass:"fas fa-quote-right font-color-light-placeholder"}),_vm._v(" "+_vm._s(_vm.user.message))]):_vm._e(),(_vm.procedure)?_c('div',[_c('div',{staticClass:"font-size-large layout-horizontal layout-middle"},[_c('ys-button',{attrs:{"lighting":"","size":"small"},on:{"click":_vm.clickMgmt}},[(_vm.$route.name === 'user_procedure_mgmt')?[_c('span',{staticClass:"fas fa-chevron-left"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("返回")])]:[_c('span',{staticClass:"fas fa-pen-alt"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("管理流程")])]],2),_c('span',{staticClass:"margin-left"},[_vm._v(_vm._s(_vm.procedure.scheme.title))]),(_vm.procedure.currentStage.state !== _vm.ProcedureState.END)?_c('span',[_vm._v(" · 第"+_vm._s(_vm.days + 1)+"天")]):_vm._e()],1),_c('div',{staticStyle:{"max-width":"100%","overflow":"hidden"}},[_c('div',{staticClass:"procedure padding-top-large font-size-small"},_vm._l((_vm.procedure.procedureStages),function(stage,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index >= _vm.currentStageIndex - 5),expression:"index >= currentStageIndex - 5"}],key:stage.globalId,class:[
          'procedure-stage',
          {
            'timeline__passed': stage.timeline < 0,
            'timeline__current': stage.timeline === 0,
            'timeline__future': stage.timeline > 0
          }
          ]},[_c('div',{staticClass:"dot before"}),_c('div',{staticClass:"dot before"}),_c('div',{staticClass:"font-align-center"},[(stage.timeline === 0)?_c('div',{staticClass:"current-stage-arrow"},[_c('span',{staticClass:"fas fa-arrow-down"})]):_vm._e(),_c('div',{staticClass:"state-name",on:{"click":function($event){return _vm.clickProcedureStage(stage)}}},[_vm._v(_vm._s(_vm.ProcedureUtils.getStateName(stage.state))+" ")])]),_c('div',{staticClass:"dot after"}),_c('div',{staticClass:"dot after"})])}),0)])]):_c('div',{staticClass:"font-color-placeholder font-size-large layout-horizontal layout-middle"},[_c('ys-button',{attrs:{"lighting":""},on:{"click":_vm.clickMgmt}},[_c('span',{staticClass:"fas fa-pen-alt"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("管理流程")])]),_c('div',{staticClass:"margin-left-small"},[_vm._v("该用户未开始干预")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }