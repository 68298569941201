<template>
  <div class="user-group layout-horizontal layout-middle padding-horizontal-large padding-vertical">
    <span :class="icon"></span>
    <el-badge :value="messages" :hidden="!messages" type="primary">
      <div
          class="group-name font-size-medium margin-left"
      >{{ group.name }}({{ group.count }})
      </div>
    </el-badge>
  </div>
</template>

<script>
import {SyntheticUserGroupIds} from "@/assets/javascript/user-group-utils";

export default {
  name: "UserGroup",
  props: {
    group: Object,
    messages: [String, Number],
  },
  computed: {
    icon: function () {
      const group = this.group;
      switch (group.id) {
        case SyntheticUserGroupIds.ALL:
          return "fas fa-users";
        case SyntheticUserGroupIds.INVESTIGATION_STATE:
          return "fas fa-file-signature";
        case SyntheticUserGroupIds.MAKE_PRESCRIPTION_STATE:
          return "fas fa-prescription-bottle";
        case SyntheticUserGroupIds.PUNCH_STATE:
          return "fas fa-calendar-check";
        case SyntheticUserGroupIds.ESTIMATE_STATE:
          return "fas fa-file-contract";
        case SyntheticUserGroupIds.PROCEDURE_END_STATE:
          return "fas fa-check-circle";
        case SyntheticUserGroupIds.UNCLOSED_CONSULTANCY:
          return "fas fa-comments";
        case SyntheticUserGroupIds.IMPORTANT:
          return "fas fa-star";
        case SyntheticUserGroupIds.TRADE:
          return "fas fa-shopping-cart";
        case SyntheticUserGroupIds.WITH_REMIND_DATE:
          return "fas fa-bell";
        default:
          return "fas fa-user-friends";
      }
    }
  }
}
</script>

<style scoped>

.user-group {
  cursor: pointer;
  user-select: none;
}

.user-group:hover {
  background-color: #f6bbc4;
}
</style>