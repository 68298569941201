<template>
  <div class="user-list-item layout-horizontal padding-left-large padding-right padding-vertical font-align-left">
    <el-badge :value="value.withUnansweredConsultancy ? '咨询' : ''">
      <user-avatar :user="value.user"
                   class="user-avatar layout-inflexible"
                   :class="{
                      'is-active': isActive,
                   }"
      ></user-avatar>
    </el-badge>
    <div class="margin-left layout-flexible" style="overflow: hidden;">
      <div class="font-size-medium">{{ value.user.name }}</div>
      <div class="font-color-secondary font-size-small font-nowrap"
           style="max-width: 200px;"
           v-if="value.message">{{ value.message }}
      </div>
    </div>
    <div class="btn-important layout-inflexible margin-right padding padding-small padding-right-large">
      <span class="fas fa-star"
            :class="[
                'user-important-star',
            {
              'is-active': value.important,
            }
            ]"></span>
    </div>
    <el-tooltip class="layout-inflexible" :content="value.user.name" placement="right" effect="light">
      <el-badge :hidden="!value.withUnansweredConsultancy" is-dot>
        <user-avatar
            :user="value.user"
            class="user-avatar"
            :class="{
                      'is-active': isActive,
                   }"
        ></user-avatar>
        <div style="height: 0px; position: relative; top: -10px; text-align: right" class="font-color-placeholder">
        <span
            v-if="value.important"
            class="fas fa-star"
            :class="[
                'user-important-star',
            {
              'is-active': value.important,
            }
            ]"></span>
        </div>
      </el-badge>
    </el-tooltip>
  </div>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar";

export default {
  name: "UserListItem",
  components: {UserAvatar},
  props: {
    value: Object,
    isActive: Boolean,
  },
}
</script>

<style scoped>

.user-list-item {
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
}

.user-list-item:hover {
  background-color: #f1f1f1;
}

.btn-important {
  color: #bec0c0;
  cursor: pointer;
}

.btn-important:hover {
  opacity: .7;
}

.btn-important.is-active {
  color: #f091a6;
}

.user-important-star.is-active {
  color: #ff5500;
}


.user-avatar {
  opacity: .2;
}

.user-avatar.is-active {
  opacity: unset;
}

</style>