var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list-item layout-horizontal padding-left-large padding-right padding-vertical font-align-left"},[_c('el-badge',{attrs:{"value":_vm.value.withUnansweredConsultancy ? '咨询' : ''}},[_c('user-avatar',{staticClass:"user-avatar layout-inflexible",class:{
                    'is-active': _vm.isActive,
                 },attrs:{"user":_vm.value.user}})],1),_c('div',{staticClass:"margin-left layout-flexible",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"font-size-medium"},[_vm._v(_vm._s(_vm.value.user.name))]),(_vm.value.message)?_c('div',{staticClass:"font-color-secondary font-size-small font-nowrap",staticStyle:{"max-width":"200px"}},[_vm._v(_vm._s(_vm.value.message)+" ")]):_vm._e()]),_c('div',{staticClass:"btn-important layout-inflexible margin-right padding padding-small padding-right-large"},[_c('span',{staticClass:"fas fa-star",class:[
              'user-important-star',
          {
            'is-active': _vm.value.important,
          }
          ]})]),_c('el-tooltip',{staticClass:"layout-inflexible",attrs:{"content":_vm.value.user.name,"placement":"right","effect":"light"}},[_c('el-badge',{attrs:{"hidden":!_vm.value.withUnansweredConsultancy,"is-dot":""}},[_c('user-avatar',{staticClass:"user-avatar",class:{
                    'is-active': _vm.isActive,
                 },attrs:{"user":_vm.value.user}}),_c('div',{staticClass:"font-color-placeholder",staticStyle:{"height":"0px","position":"relative","top":"-10px","text-align":"right"}},[(_vm.value.important)?_c('span',{staticClass:"fas fa-star",class:[
              'user-important-star',
          {
            'is-active': _vm.value.important,
          }
          ]}):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }