<!--用户分组-->
<template>
  <div class="padding-top-large">
    <div
        :class="[
          'menu-item layout-horizontal layout-middle padding-horizontal-large',
          {
            'searching': isSearching,
          }
      ]"
        @click="clickSearch">
      <span :class="[
          'fas fa-search',
          {
            'font-color-placeholder': isSearching,
          }
          ]"></span>
      <ys-input
          v-show="isSearching"
          ref="inputSearch"
          placeholder="搜索"
          :show-background="false"
          style="padding: 0px;"
          size="large"
          class="font-align-left font-size-medium"
          @input="handleSearchInput"
      ></ys-input>
      <span v-if="!isSearching" class="margin-left font-size-medium padding-vertical">搜索</span>
    </div>
    <div class="padding-horizontal-large">
      <div class="divider margin-vertical"></div>
    </div>
    <user-group
        v-if="withRemindDateGroup"
        :group="withRemindDateGroup"
        :messages="remindUserCount < 99 ? remindUserCount : '99+'"
        @click.native="clickGroup(withRemindDateGroup)"
    ></user-group>
    <user-group
        v-if="allUserGroup"
        :group="allUserGroup"
        @click.native="clickGroup(allUserGroup)"
    ></user-group>
    <user-group
        v-if="investigationStateGroup"
        :group="investigationStateGroup"
        @click.native="clickGroup(investigationStateGroup)"
    ></user-group>
    <user-group
        v-if="makePrescriptionStateGroup"
        :group="makePrescriptionStateGroup"
        @click.native="clickGroup(makePrescriptionStateGroup)"
    ></user-group>
    <user-group
        v-if="estimateStateGroup"
        :group="estimateStateGroup"
        @click.native="clickGroup(estimateStateGroup)"
    ></user-group>
    <user-group
        v-if="punchStateGroup"
        :group="punchStateGroup"
        @click.native="clickGroup(punchStateGroup)"
    ></user-group>
    <user-group
        v-if="unclosedConsultancyGroup"
        :group="unclosedConsultancyGroup"
        :messages="unreadMessageCount > 0 ? '新消息' : ''"
        @click.native="clickGroup(unclosedConsultancyGroup)"
    ></user-group>
    <user-group
        v-if="importantUserGroup"
        :group="importantUserGroup"
        @click.native="clickGroup(importantUserGroup)"
    ></user-group>
    <user-group
        v-if="withTrialGroup"
        :group="withTrialGroup"
        @click.native="clickGroup(withTrialGroup)"
    ></user-group>
    <user-group
        v-if="procedureEndGroup"
        :group="procedureEndGroup"
        @click.native="clickGroup(procedureEndGroup)"
    ></user-group>
    <div class="padding-horizontal-large">
      <div class="divider margin-vertical"></div>
    </div>
    <user-group
        v-if="tradeGroup"
        :group="tradeGroup"
        @click.native="clickGroup(tradeGroup)"
    ></user-group>
    <div
        class="menu-item layout-horizontal layout-middle padding-horizontal-large padding-vertical"
        @click="clickSetting"
        v-if="$isMgr()"
    >
      <span class="fas fa-cog"></span>
      <span class="margin-left-small font-size-medium">设置</span>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {SyntheticUserGroupIds} from "@/assets/javascript/user-group-utils";
import UserGroup from "@/pages/user/panel/UserGroup";
import YsInput from "@/components/wedigets/YsInput";

export default {
  name: "UserGroupsPanel",
  components: {YsInput, UserGroup},
  mixins: [httpapi],
  props: {
    organizationId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 正在搜索
       */
      isSearching: false,

      /**
       * 系统分组
       */
      /** 全部分组 **/
      allUserGroup: null,
      /** 营养筛查 **/
      investigationStateGroup: null,
      /** 制定处方 **/
      makePrescriptionStateGroup: null,
      /** 评估 **/
      estimateStateGroup: null,
      /** 打卡和监督 **/
      punchStateGroup: null,
      /** 干预完成 **/
      procedureEndGroup: null,
      /** 咨询 **/
      unclosedConsultancyGroup: null,
      /** 重点关注 **/
      importantUserGroup: null,
      /** 订单 **/
      tradeGroup: null,
      /** 试用用户 **/
      withTrialGroup: null,
      /** 提醒一下 **/
      withRemindDateGroup: null,
      /** 其他分组 **/
      otherGroups: [],

      unreadMessageCount: 0,
    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;
        if (this.organizationId) {
          this.loadUserGroups();
          this.loadUnreadMessageCount();
        }
      },
      immediate: true,
    }
  },
  computed: {
    remindUserCount() {
      return this.withRemindDateGroup?.count;
    }
  },
  mounted() {
    window.eventBus.$on('notify:user_important', this.loadUserGroups);
    window.eventBus.$on('notify:procedure', this.loadUserGroups);
    window.eventBus.$on('notify:trade', this.loadUserGroups);
    window.eventBus.$on('notify:message', this.loadUnreadMessageCount);
    window.eventBus.$on('update:user_remark', this.loadUserGroups);
  },
  methods: {
    clickSearch: function () {
      this.isSearching = true;
      this.$nextTick(() => this.$refs.inputSearch.focus());
    },
    handleSearchInput: function (search) {
      this.$emit('search', search);
    },
    clickGroup: function (userGroup) {
      this.isSearching = false;
      this.$emit('groupchange', userGroup);
    },
    clickSetting: function () {
      this.$router.push({name: 'organization_content'});
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadUserGroups: function () {
      if (!this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/user/groups/${this.organizationId}`,
        data: {},
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.prepareUserGroupsList(res.data);
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },
    loadUnreadMessageCount: function () {
      this.$reqGet({
        path: '/consultancy/unread_count/get/by_organization_member',
        data: {
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.unreadMessageCount = res.data;
          })
    },
    prepareUserGroupsList: function (userGroups) {
      let allUserGroup = null;
      let investigationStateGroup = null;
      let makePrescriptionStateGroup = null;
      let estimatStateGroup = null;
      let punchStateGroup = null;
      let procedureEndGroup = null;
      let unclosedConsultancyGroup = null;
      let importantUserGroup = null;
      let withRemindDateGroup = null;
      let withTrialGroup = null;
      let tradeGroup = null;
      let otherGroups = [];
      for (let userGroup of userGroups) {
        switch (userGroup.id) {
          case SyntheticUserGroupIds.ALL:
            allUserGroup = userGroup;
            break;
          case SyntheticUserGroupIds.INVESTIGATION_STATE:
            investigationStateGroup = userGroup;
            break;
          case SyntheticUserGroupIds.MAKE_PRESCRIPTION_STATE:
            makePrescriptionStateGroup = userGroup;
            break;
          case SyntheticUserGroupIds.ESTIMATE_STATE:
            estimatStateGroup = userGroup;
            break;
          case SyntheticUserGroupIds.PUNCH_STATE:
            punchStateGroup = userGroup;
            break;
          case SyntheticUserGroupIds.PROCEDURE_END_STATE:
            procedureEndGroup = userGroup;
            break;
          case SyntheticUserGroupIds.UNCLOSED_CONSULTANCY:
            unclosedConsultancyGroup = userGroup;
            break;
          case SyntheticUserGroupIds.IMPORTANT:
            importantUserGroup = userGroup;
            break;
          case SyntheticUserGroupIds.TRADE:
            tradeGroup = userGroup;
            break;
          case SyntheticUserGroupIds.WITH_TRIAL:
            withTrialGroup = userGroup;
            break;
          case SyntheticUserGroupIds.WITH_REMIND_DATE:
            withRemindDateGroup = userGroup;
            break;
          default:
            otherGroups.push(userGroup);
            break;
        }
      }

      this.allUserGroup = allUserGroup;
      this.investigationStateGroup = investigationStateGroup;
      this.makePrescriptionStateGroup = makePrescriptionStateGroup;
      this.estimateStateGroup = estimatStateGroup;
      this.punchStateGroup = punchStateGroup;
      this.procedureEndGroup = procedureEndGroup;
      this.unclosedConsultancyGroup = unclosedConsultancyGroup;
      this.importantUserGroup = importantUserGroup;
      this.tradeGroup = tradeGroup;
      this.withTrialGroup = withTrialGroup;
      this.withRemindDateGroup = withRemindDateGroup;
      this.otherGroups = otherGroups;
    }
  }
}
</script>

<style scoped>

.user-groups {
  background-color: #f091a6;
  color: white;
}

.menu-item {
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.menu-item:hover {
  background-color: #f6bbc4;
}

.menu-item.searching {
  background-color: white;
  border-radius: 4px;
  border: 2px solid #f091a6;
}


</style>