<template>
  <div class="user-list-panel">
    <div class="switch-button-group">
      <div
          :class="[
              'switch-button',
              {
                'is-active': mode === 'left',
              }
          ]"
      >
        <span class="fas fa-list"></span></div>
      <div
          :class="[
               'switch-button',
               {
                 'is-active': mode === 'right',
               }
          ]"
      ><span class="fas fa-user"></span></div>
    </div>
    <div :class="[
        'content layout-horizontal ',
        {
          'animated': isAnimated,
        },
        'mode__' + mode,
        ]"
         style="height: 100%;"
         @click="handleClick">
      <div class="sub-panel-left" style="overflow: hidden;">
        <user-list
            ref="userList"
            :organization-id="organizationId"
            style="width: 100%; height: 100%;"
            @change="handleUserChange"

        ></user-list>
      </div>
      <div class="sub-panel-right">
        <user-info
            :organization-id="organizationId"
            style="width: 100%; height: 100%;"
            :value="user"
            @accountchange="handleAccountChange"
        ></user-info>
      </div>
    </div>

  </div>
</template>

<script>
import UserList from "@/pages/user/panel/UserList";
import UserInfo from "@/pages/user/panel/UserInfo";

export default {
  name: "UserListPanel",
  components: {UserInfo, UserList},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      mode: 'left',
      isAnimated: false,

      user: null,
    }
  },
  watch: {
    organizationId: function () {
      this.setMode('left');
      this.user = null;
    }
  },

  methods: {
    handleClick: function () {
      this.setMode(this.mode === 'left' ? 'right' : 'left');
    },
    handleUserChange: function (e) {
      this.user = e.user;
      this.setMode("right");
      this.$emit('userchange', e.user);
    },
    handleAccountChange: function (account) {
      this.$refs.userList.setAccountId(account.id);
      this.setMode("left");
    },
    setMode(mode) {
      let oldMode = this.mode;
      this.mode = mode;
      this.isAnimated = oldMode != mode;
    },
    loadUsersByGroup(userGroup) {
      this.$refs.userList.setGroupId(userGroup.id);
      this.setMode("left");
    },
    loadUsersByAccount(account) {
      this.$refs.userList.setAccountId(account.id);
      this.setMode("left");
    },
    loadUsersBySearch(search) {
      this.$refs.userList.setSearch(search);
      this.setMode("left")
    }
  }
}
</script>

<style scoped>

@keyframes anim-slide-to-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes anim-slide-to-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}


.user-list-panel {
  overflow: hidden;
}

.content {
  width: 200%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 30px;

}

.content.mode__left {
  transform: translateX(0%);
}

.content.mode__left.animated {
  animation: anim-slide-to-left .3s;
}

.content.mode__right {
  transform: translateX(-50%);
}

.content.mode__right.animated {
  animation: anim-slide-to-right .3s;
}

.sub-panel-left {
  width: calc(100% + 88px);
}

.sub-panel-right {
  width: calc(100% - 88px);
}

.switch-button-group {
  height: 0px;
  width: max-content;
  position: relative;
  top: 5px;
  left: 5px;
}

.switch-button-group > .switch-button {
  display: inline-block;
  padding: 2px 6px;
  background-color: #f1f1f1;
  color: #bec0c0;
  cursor: pointer;
  user-select: none;
  font-size: 4px;
}

.switch-button-group > .switch-button.is-active {
  background-color: #f091a6;
  color: white;
}

.switch-button-group > .switch-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.switch-button-group > .switch-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}


</style>