export const SyntheticUserGroupIds = {

    /**
     * 全部分组
     */
    ALL: 0,

    /**
     * 营养筛查
     */
    INVESTIGATION_STATE: -1,

    /**
     * 制定饮食处方
     */
    MAKE_PRESCRIPTION_STATE: -2,

    /**
     * 评估
     */
    ESTIMATE_STATE: -3,

    /**
     * 打卡监督
     */
    PUNCH_STATE: -4,

    /**
     * 干预完成
     */
    PROCEDURE_END_STATE: -5,

    /**
     * 咨询
     */
    UNCLOSED_CONSULTANCY: -6,

    /**
     * 重点关注
     */
    IMPORTANT: -7,

    /**
     * 订单
     */
    TRADE: -8,

    /**
     * 试用用户
     */
    WITH_TRIAL: -9,

    /**
     * 提醒一下
     */
    WITH_REMIND_DATE: -10,

}