<template>
  <div class="user-info">
    <div v-if="value">
      <div class="padding">
        <div class="name font-align-center margin-bottom-small" @click.stop="()=>{}">
          <span class="font-size-extra-large">{{ user.name }}</span>
          <div class="margin-top-small">
          <span class="btn-important font-size-medium fas fa-star margin-left-small"
                :class="{
                'is-active': value.important
              }"
                @click="clickImportantStar"
          ></span>
            <span class="btn-tags font-size-medium fas fa-tag margin-left-small"
                  :class="{
                  'is-active': value.tags.length > 0
                }"
                  @click.stop="clickUserTag"
            ></span>
          </div>
        </div>
        <div class="menu">
          <div class="menu-item-wrap" @click.stop="clickUserRemarkMenu">
            <div class="menu-item card layout-horizontal layout-middle"
                 :class="{
              'is-active': this.$route.name === 'user_remark_list'
            }">
              <span class="icon fas fa-paperclip"></span>
              <span class="margin-left-small">便签</span>
              <span v-if="value.remarkCount > 0">({{value.remarkCount}})</span>
              <div class="layout-flexible"></div>
              <div class="remark-reminder font-size-small margin-left-small" v-if="value.remindRemarkCount > 0">
                <span>{{ value.remindRemarkCount }}<i class="fas fa-angle-right margin-left-small"></i></span>
              </div>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickPunchMenu">
            <div class="menu-item card"
                 :class="{
              'is-active': this.$route.name === 'user_punch_mgmt'
            }">
              <span class="icon fas fa-calendar-check"></span>
              <span class="margin-left-small">打卡</span>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickQuestionnaireMenu">
            <div class="menu-item card"
                 :class="{
              'is-active': this.$route.name === 'user_questionnaire_mgmt',
            }">
              <span class="icon fas fa-table"></span>
              <span class="margin-left-small">问卷</span>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickPrescriptionMenu">
            <div class="menu-item card"
                 :class="{
              'is-active': this.$route.name === 'user_prescription_mgmt',
            }">
              <span class="icon fas fa-prescription-bottle"></span>
              <span class="margin-left-small">饮食处方</span>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickCookeryBookMenu">
            <div class="menu-item card"
                 :class="{
                    'is-active': this.$route.name === 'user_cookery_book_mgmt' || this.$route.name === 'user_cookery_book',
                 }"
            >
              <span class="icon fas fa-utensils"></span>
              <span class="margin-left-small">食谱管理</span>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickReportMenu">
            <div class="menu-item card"
                 :class="{
              'is-active': this.$route.name === 'user_report_mgmt'
            }">
              <span class="icon fas fa-file-medical"></span>
              <span class="margin-left-small">健康报告</span>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickConsultancyMenu">
            <div class="menu-item card"
                 :class="{
              'is-active': this.$route.name === 'user_consultancy'
            }">
              <span class="icon fas fa-comment"></span>
              <el-badge :value="unreadMessageCount" :hidden="!unreadMessageCount" type="primary">
                <span class="margin-left-small">咨询</span>
              </el-badge>
            </div>
          </div>
          <div class="menu-item-wrap" @click.stop="clickMiniToolMenu">
            <div class="menu-item card layout-horizontal layout-middle"
                 :class="{
              'is-active': this.$route.name === 'user_minitool'
              }">
              <span class="icon fas fa-tools"></span>
              <span class="margin-left-small">小工具</span>
              <div class="layout-flexible"></div>
              <div class="mini-tool" v-for="(minitoolEntry, index) in minitoolList" :key="minitoolEntry.id"
                   v-show="index < 3">
                <el-image class="icon-mini-tool layout-inflexible"
                          :src="$prepareImageUrl(minitoolEntry.logo)"
                >
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="user.phone"
            class="card padding margin-bottom-small"
            style="overflow: hidden"
            @click.stop="clickPhoneCard"
        >
          <div style="height: 0px; position: relative; top: -20px; text-align: right; font-size: 32px; opacity: .3;">
            <span class="fas fa-phone font-color-primary"></span>
          </div>
          <div class="font-align-left"> 联系电话：</div>
          <div class="font-size-medium margin-top margin-left font-align-left font-color-primary">{{
              user.phone.phone
            }}
          </div>
        </div>
        <div
            class="card padding"
            style="overflow: hidden"
            @click.stop="clickAccountCard"
        >
          <div style="height: 0px; position: relative; top: -20px; text-align: right; font-size: 32px; opacity: .3;">
            <span class="fab fa-weixin font-color-success"></span>
          </div>
          <div class="font-align-left">微信号：</div>
          <div class="layout-horizontal margin-top margin-left font-size-medium">
            <user-avatar :user="user.account" size="small"></user-avatar>
            <div class="margin-left font-align-left">
              <div>{{ user.account.nickName }}</div>
            </div>
          </div>
          <div class="font-color-primary font-size-base font-align-left margin-top-small">查看同账号下其他用户</div>
        </div>
      </div>
    </div>
    <div v-else class="empty layout-vertical layout-center font-color-placeholder">
      <div class="font-color-light-placeholder" style="font-size: 64px; margin-top: 100px;">
        <span class="fas fa-user"></span>
      </div>
      <div class="margin-top font-size-medium">未选择用户</div>
    </div>
    <user-tags-setting-dialog
        :organization-id="organizationId"
        :user="value"
        :visible.sync="userTagSettingDialogVisible"
    ></user-tags-setting-dialog>
  </div>

</template>

<script>
import UserAvatar from "@/components/user/UserAvatar";
import UserTagsSettingDialog from "@/pages/user_bak/dialog/UserTagsSettingDialog";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "UserInfo",
  mixins: [httpapi],
  components: {UserTagsSettingDialog, UserAvatar},
  props: {
    value: Object,
    organizationId: Number,
  },
  data() {
    return {
      user: null,

      userTagSettingDialogVisible: false,

      minitoolList: [],

      unreadMessageCount: 0,
    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;
      },
      immediate: true,
    },
    value: {
      handler: function (value) {
        this.user = value?.user;
        this.minitoolList = [];
        this.unreadMessageCount = 0;
        if (this.user) {
          this.loadMiniToolEntries();
          this.loadUnreadMessageCount();
        }
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('notify:user_tags', this.loadMiniToolEntries);
    window.eventBus.$on('notify:message', this.loadUnreadMessageCount);
  },
  methods: {
    clickPhoneCard: function () {

    },
    clickAccountCard: function () {
      this.$emit('accountchange', this.user.account);
    },
    clickUserRemarkMenu: function () {
      this.$router.push({name: 'user_remark_list'});
    },
    clickPunchMenu: function () {
      this.$router.push({name: 'user_punch_mgmt'});
    },
    clickQuestionnaireMenu: function () {
      this.$router.push({name: 'user_questionnaire_mgmt'});
    },
    clickPrescriptionMenu: function () {
      this.$router.push({name: 'user_prescription_mgmt'})
    },
    clickCookeryBookMenu: function () {
      this.$router.push({name: 'user_cookery_book_mgmt'});
    },
    clickReportMenu: function () {
      this.$router.push({name: 'user_report_mgmt'})
    },
    clickConsultancyMenu: function () {
      this.$router.push({name: 'user_consultancy'})
    },
    clickTradeMenu: function () {
      this.$router.push({name: 'user_trade_mgmt'});
    },
    clickMiniToolMenu: function () {
      this.$router.push({name: 'user_minitool'});
    },
    clickImportantStar: function () {
      this.setUserImportant(!this.value.important);
    },
    clickUserTag: function () {
      this.userTagSettingDialogVisible = true;
    },
    loadMiniToolEntries() {
      if (!this.user) return;
      this.$reqGet({
        path: '/minitool/list/get/by/user/organization',
        data: {
          userId: this.user.id,
          organizationId: this.organizationId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.minitoolList = res.data;
          })
    },
    loadUnreadMessageCount() {
      if (!this.user) return;
      this.$reqGet({
        path: '/consultancy/unread_count/get/of/account',
        data: {
          organizationId: this.organizationId,
          accountId: this.user.account.id,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.unreadMessageCount = res.data;
          })
    },
    setUserImportant: function (important) {
      this.$reqPatch({
        path: `/user/important/${this.organizationId}/${this.user.id}/${important}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.value.important = important;
            window.eventBus.$emit('notify:user_important');
          })
          .catch(() => this.$message.error('操作失败'));
    }
  }
}
</script>

<style scoped>

.user-info {
  overflow: hidden;
}

.empty {
  width: 100%;
  height: 100%;
}

.name {
  background-color: #faf4f5;
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px #f091a6 solid;
}

.btn-important {
  cursor: pointer;
  user-select: none;
  color: #bec0c0;
}

.btn-important.is-active {
  color: #ff5500;
}

.btn-tags {
  cursor: pointer;
  user-select: none;
  color: #bec0c0;
}

.btn-tags.is-active {
  color: #ff5500;
}

.message {
  line-height: 32px;
}

.menu-item-wrap {
  height: 30px;
  margin-bottom: 10px;
}

.menu-item {
  padding: 8px 16px;
  text-align: left;
  font-size: 12px;
  cursor: pointer;
  border-radius: 8px;
  height: 110%;
  color: #1f1f1f;
}

.menu-item .icon {
  color: #8c939d;
}

.menu-item:hover {
  background-color: #f1f1f1;
}

.card {
  cursor: pointer;
  box-shadow: 0 0 2px #dadada;
  border-radius: 8px;
}

.card:hover {
  background-color: #f1f1f1;
}

.card.is-active {
  background-color: #f091a6;
  color: white !important;
  box-shadow: 0 0 8px #f091a6;
  font-weight: bold;
}

.card.is-active .icon {
  color: white;
}

.mini-tool {
  width: 10px;
  text-align: right;
  opacity: .5;

}

.icon-mini-tool {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  position: relative;
  left: -14px;
  box-shadow: 0 0 4px #bec0c0;
}

.remark-reminder {
  background-color: #409EFF;
  padding: 2px 8px;
  border-radius: 100px;
  color: white;
  border: 2px solid #ff5500;
}

</style>