<template>
  <div>
    <background></background>
    <notification-bar></notification-bar>
    <div class="page layout-horizontal">
      <user-groups-panel
          class="user-groups layout-inflexible"
          :organization-id="organizationId"
          @groupchange="handleUserGroupChange"
          @search="handleUserSearch"
      ></user-groups-panel>
      <div class="divider divider-vertical layout-inflexible"></div>
      <div class="layout-horizontal layout-flexible" v-if="!isTradeList">
        <user-list-panel
            ref="userList"
            class="user-list layout-inflexible"
            style="height: 100%;"
            :organization-id="organizationId"
            @userchange="handleUserChange"
        ></user-list-panel>
        <div class="divider divider-vertical layout-inflexible"></div>
        <div class="layout-flexible" style="overflow: hidden;">
          <div class="sub-content font-align-left layout-vertical">
            <template v-if="user">
              <div class="title layout-inflexible" v-if="$route.name !== 'user_cookery_book'">
                <procedure
                    :user="user"
                    :organization-id="organizationId"
                ></procedure>
              </div>
              <div class="layout-flexible" style="overflow: hidden;">
                <router-view
                    class="router-view"
                    :user-id="user.id"
                    :organization-id="organizationId"
                    :account-id="user.user.account.id"
                ></router-view>
              </div>
            </template>
            <template v-else>
              <div class="padding padding-large font-size-extra-large font-color-placeholder"
              >
                <span class="fas fa-times"></span> 未选择用户
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="layout-horizontal layout-flexible" v-else>
        <scheme-trade-list
            :organization-id="organizationId"
        ></scheme-trade-list>
      </div>
    </div>
  </div>
</template>

<script>
import UserGroupsPanel from "@/pages/user/panel/UserGroupsPanel";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import NotificationBar from "@/components/notificationbar/NotificationBar";
import UserListPanel from "@/pages/user/panel/UserListPanel";
import Procedure from "@/pages/user/panel/Procedure";
import {SyntheticUserGroupIds} from "@/assets/javascript/user-group-utils";
import TradePanel from "@/pages/user/trade/TradePanel";
import SchemeTradeList from "@/pages/trade/SchemeTradeList";
import Background from "@/components/background/Background";

export default {
  name: "Users",
  components: {
    Background,
    SchemeTradeList,
    Procedure,
    UserListPanel,
    NotificationBar,
    UserGroupsPanel
  },
  data() {
    return {
      organizationId: null,

      user: null,

      userGroup: null,

      isTradeList: false,
    }
  },
  mounted() {
    this.organizationId = OrganizationUtils.$getOrganization()?.id;
    window.eventBus.$on('organization_changed', this.handleOrganizationChanged);
  },
  methods: {
    handleOrganizationChanged: function (organization) {
      this.organizationId = organization?.id;
      this.user = null;
    },
    handleUserGroupChange: function (userGroup) {
      this.userGroup = userGroup;
      if (userGroup.id === SyntheticUserGroupIds.TRADE) {
        this.isTradeList = true;
      } else {
        this.isTradeList = false;
        this.$refs.userList.loadUsersByGroup(userGroup);
      }
    },
    handleUserSearch: function (search) {
      this.$refs.userList.loadUsersBySearch(search);
    },
    handleUserChange: function (user) {
      if (this.user?.user.id === user.user.id) return;
      this.user = user;

      this.isTradeList = false;

      switch (this.userGroup?.id) {
        case SyntheticUserGroupIds.MAKE_PRESCRIPTION_STATE:
          this.$router.push({name: 'user_prescription_mgmt'});
          break;
        case SyntheticUserGroupIds.UNCLOSED_CONSULTANCY:
          this.$router.push({name: 'user_consultancy'})
          break;
        case SyntheticUserGroupIds.INVESTIGATION_STATE:
        case SyntheticUserGroupIds.ESTIMATE_STATE:
          this.$router.push({name: 'user_questionnaire_mgmt'});
          break;
        case SyntheticUserGroupIds.WITH_REMIND_DATE:
          this.$router.push({name: 'user_remark_list'});
          break;
        default:
          this.$router.push({name: 'user_punch_mgmt'});
          break;
      }
    }
  },

}
</script>

<style scoped>

.page {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

.user-groups {
  width: max-content;
  max-width: 156px;
}

.user-list {
  width: 256px;
  box-sizing: border-box;
}

.sub-content {
  height: 100%;
}

.title {
  padding: 8px;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 2px solid #f6bbc4;
}

.router-view {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
}

</style>