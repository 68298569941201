var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info"},[(_vm.value)?_c('div',[_c('div',{staticClass:"padding"},[_c('div',{staticClass:"name font-align-center margin-bottom-small",on:{"click":function($event){$event.stopPropagation();return (function (){})($event)}}},[_c('span',{staticClass:"font-size-extra-large"},[_vm._v(_vm._s(_vm.user.name))]),_c('div',{staticClass:"margin-top-small"},[_c('span',{staticClass:"btn-important font-size-medium fas fa-star margin-left-small",class:{
              'is-active': _vm.value.important
            },on:{"click":_vm.clickImportantStar}}),_c('span',{staticClass:"btn-tags font-size-medium fas fa-tag margin-left-small",class:{
                'is-active': _vm.value.tags.length > 0
              },on:{"click":function($event){$event.stopPropagation();return _vm.clickUserTag($event)}}})])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickUserRemarkMenu($event)}}},[_c('div',{staticClass:"menu-item card layout-horizontal layout-middle",class:{
            'is-active': this.$route.name === 'user_remark_list'
          }},[_c('span',{staticClass:"icon fas fa-paperclip"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("便签")]),(_vm.value.remarkCount > 0)?_c('span',[_vm._v("("+_vm._s(_vm.value.remarkCount)+")")]):_vm._e(),_c('div',{staticClass:"layout-flexible"}),(_vm.value.remindRemarkCount > 0)?_c('div',{staticClass:"remark-reminder font-size-small margin-left-small"},[_c('span',[_vm._v(_vm._s(_vm.value.remindRemarkCount)),_c('i',{staticClass:"fas fa-angle-right margin-left-small"})])]):_vm._e()])]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickPunchMenu($event)}}},[_c('div',{staticClass:"menu-item card",class:{
            'is-active': this.$route.name === 'user_punch_mgmt'
          }},[_c('span',{staticClass:"icon fas fa-calendar-check"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("打卡")])])]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickQuestionnaireMenu($event)}}},[_c('div',{staticClass:"menu-item card",class:{
            'is-active': this.$route.name === 'user_questionnaire_mgmt',
          }},[_c('span',{staticClass:"icon fas fa-table"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("问卷")])])]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickPrescriptionMenu($event)}}},[_c('div',{staticClass:"menu-item card",class:{
            'is-active': this.$route.name === 'user_prescription_mgmt',
          }},[_c('span',{staticClass:"icon fas fa-prescription-bottle"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("饮食处方")])])]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickCookeryBookMenu($event)}}},[_c('div',{staticClass:"menu-item card",class:{
                  'is-active': this.$route.name === 'user_cookery_book_mgmt' || this.$route.name === 'user_cookery_book',
               }},[_c('span',{staticClass:"icon fas fa-utensils"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("食谱管理")])])]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickReportMenu($event)}}},[_c('div',{staticClass:"menu-item card",class:{
            'is-active': this.$route.name === 'user_report_mgmt'
          }},[_c('span',{staticClass:"icon fas fa-file-medical"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("健康报告")])])]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickConsultancyMenu($event)}}},[_c('div',{staticClass:"menu-item card",class:{
            'is-active': this.$route.name === 'user_consultancy'
          }},[_c('span',{staticClass:"icon fas fa-comment"}),_c('el-badge',{attrs:{"value":_vm.unreadMessageCount,"hidden":!_vm.unreadMessageCount,"type":"primary"}},[_c('span',{staticClass:"margin-left-small"},[_vm._v("咨询")])])],1)]),_c('div',{staticClass:"menu-item-wrap",on:{"click":function($event){$event.stopPropagation();return _vm.clickMiniToolMenu($event)}}},[_c('div',{staticClass:"menu-item card layout-horizontal layout-middle",class:{
            'is-active': this.$route.name === 'user_minitool'
            }},[_c('span',{staticClass:"icon fas fa-tools"}),_c('span',{staticClass:"margin-left-small"},[_vm._v("小工具")]),_c('div',{staticClass:"layout-flexible"}),_vm._l((_vm.minitoolList),function(minitoolEntry,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < 3),expression:"index < 3"}],key:minitoolEntry.id,staticClass:"mini-tool"},[_c('el-image',{staticClass:"icon-mini-tool layout-inflexible",attrs:{"src":_vm.$prepareImageUrl(minitoolEntry.logo)}})],1)})],2)])]),(_vm.user.phone)?_c('div',{staticClass:"card padding margin-bottom-small",staticStyle:{"overflow":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.clickPhoneCard($event)}}},[_vm._m(0),_c('div',{staticClass:"font-align-left"},[_vm._v(" 联系电话：")]),_c('div',{staticClass:"font-size-medium margin-top margin-left font-align-left font-color-primary"},[_vm._v(_vm._s(_vm.user.phone.phone)+" ")])]):_vm._e(),_c('div',{staticClass:"card padding",staticStyle:{"overflow":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.clickAccountCard($event)}}},[_vm._m(1),_c('div',{staticClass:"font-align-left"},[_vm._v("微信号：")]),_c('div',{staticClass:"layout-horizontal margin-top margin-left font-size-medium"},[_c('user-avatar',{attrs:{"user":_vm.user.account,"size":"small"}}),_c('div',{staticClass:"margin-left font-align-left"},[_c('div',[_vm._v(_vm._s(_vm.user.account.nickName))])])],1),_c('div',{staticClass:"font-color-primary font-size-base font-align-left margin-top-small"},[_vm._v("查看同账号下其他用户")])])])]):_c('div',{staticClass:"empty layout-vertical layout-center font-color-placeholder"},[_vm._m(2),_c('div',{staticClass:"margin-top font-size-medium"},[_vm._v("未选择用户")])]),_c('user-tags-setting-dialog',{attrs:{"organization-id":_vm.organizationId,"user":_vm.value,"visible":_vm.userTagSettingDialogVisible},on:{"update:visible":function($event){_vm.userTagSettingDialogVisible=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"0px","position":"relative","top":"-20px","text-align":"right","font-size":"32px","opacity":".3"}},[_c('span',{staticClass:"fas fa-phone font-color-primary"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"0px","position":"relative","top":"-20px","text-align":"right","font-size":"32px","opacity":".3"}},[_c('span',{staticClass:"fab fa-weixin font-color-success"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-color-light-placeholder",staticStyle:{"font-size":"64px","margin-top":"100px"}},[_c('span',{staticClass:"fas fa-user"})])}]

export { render, staticRenderFns }