<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" title="用户标签和优先级">
    <ys-infinite-scroll-view @loadmore="loadMoreSupportedUserTags" style="height: 50vh;">
      <!--<template v-if="user">
        <div class="margin-top margin-horizontal">设置用户优先级：</div>
        <div class="layout-horizontal padding-horizontal margin-vertical-large">
          <div class="user-priority user-priority__low"
               :class="{'is-active': user.priority && user.priority.priority === Priority.LOW}"
               @click="handlePriorityClick(Priority.LOW)"
          >低
          </div>
          <div class="user-priority user-priority__medium"
               :class="{'is-active': user.priority && user.priority.priority === Priority.MEDIUM}"
               @click="handlePriorityClick(Priority.MEDIUM)"
          >中
          </div>
          <div class="user-priority user-priority__high"
               :class="{'is-active': user.priority && user.priority.priority === Priority.HIGH}"
               @click="handlePriorityClick(Priority.HIGH)"
          >高
          </div>
        </div>
      </template>-->
      <el-collapse-transition>
        <div v-if="user && this.user.tags.length > 0">
          <div class="margin-top-large margin-horizontal">{{ user.user.name }}的用户标签：</div>
          <div class="layout-horizontal layout-wrap margin-horizontal">
            <div class="user-tag-item margin margin-small is-active" style="padding-right: 2px;"
                 v-for="userTag in this.user.tags" :key="userTag.id"
                 @click="handleUserTagClick(userTag)">
              <span>{{ userTag.tag }}</span>
              <span class="btn-remove el-icon-close font-size-small" style="padding: 2px 4px;"></span>
            </div>
          </div>
        </div>
      </el-collapse-transition>
      <div class="margin-top-large margin-horizontal">选择标签：</div>
      <div class="layout-horizontal layout-wrap margin-horizontal">
        <div class="user-tag-item margin margin-small"
             :class="{'is-active': supportedUserTag.__selected}"
             v-for="supportedUserTag in this.supportedUserTags" :key="supportedUserTag.id"
             @click="handleSupportedUserTagClick(supportedUserTag)">
          {{ supportedUserTag.tag }}
        </div>
      </div>
    </ys-infinite-scroll-view>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import Priority from "@/assets/javascript/prority";

function doLoadSupportedUserTags(pageNum) {
  let organizationId = this.organizationId;
  this.$reqGet({
    path: '/user/organization/tags/get',
    data: {
      organizationId: 0,
      pageNum,
      pageSize: 25,
    }
  }).then((res) => {
    if (organizationId === this.organizationId) {
      this.$appendAfter(this.supportedUserTags, res.data.list);
      this.pageNum = pageNum;
      this.totalpages = res.data.pages;
      this.refreshSupportedUserTagsState();
    }
  });
}

function doSetUserPriority(userId, organizationId, priority) {
  this.$reqPost({
    path: '/user/priority/set',
    data: {
      userId,
      organizationId,
      priority,
      score: 1,
    }
  });
}

function doSetUserTags(userId, organizationId, tagIds) {
  this.$reqPost({
    path: '/user/tags/set',
    data: {
      userId,
      organizationId,
      tagIds: tagIds.join(','),
    }
  })
      .then(() => {
        window.eventBus.$emit('notify:user_tags');
      })
}

export default {
  name: "UserTagsSettingDialog",
  mixins: [httpapi],
  components: {YsInfiniteScrollView, YsDialog},
  props: {
    organizationId: Number,
    user: Object,
    visible: Boolean,
  },
  data() {
    return {
      Priority,
      isVisible: false,
      supportedUserTags: [],
      pageNum: 1,
      totalPages: 0,
      setUserTagsTimeoutId: null,
      setUserPriorityTimeoutId: null,
    }
  },
  watch: {
    organizationId: {
      handler() {
        if (this.organizationId) {
          this.supportedUserTags = [];
          this.pageNum = 1;
          this.totalPages = 0;
          this.loadSupportedUserTags();
        }
      },
      immediate: true,
    },
    user: {
      handler() {
        this.refreshSupportedUserTagsState();
      },
      immediate: true,
    },
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    }
  },
  methods: {
    loadSupportedUserTags(pageNum = 1) {
      doLoadSupportedUserTags.bind(this)(pageNum);
    },
    loadMoreSupportedUserTags() {
      if (this.pageNum < this.totalPages) {
        this.loadSupportedUserTags(this.pageNum + 1);
      }
    },
    handlePriorityClick(priority) {
      this.user.priority = {
        priority,
      }
      if (this.setUserPriorityTimeoutId) {
        clearTimeout(this.setUserPriorityTimeoutId);
      }
      let userId = this.user.user.id;
      let organizationId = this.organizationId;
      this.setUserPriorityTimeoutId = setTimeout(() => {
        doSetUserPriority.bind(this)(userId, organizationId, priority);
      }, 200);
    },
    handleUserTagClick(userTag) {
      for (let n = this.user.tags.length - 1; n >= 0; n--) {
        if (this.user.tags[n].id === userTag.id) {
          this.user.tags.splice(n, 1);
        }
      }
      this.refreshSupportedUserTagsState();
      this.setUserTags();
    },
    handleSupportedUserTagClick(supportedUserTag) {
      if (!this.user) return;
      if (supportedUserTag.__selected) {
        this.handleUserTagClick(supportedUserTag);
      } else {
        let duplicated = false;
        for (let userTag of this.user.tags) {
          if (userTag.id === supportedUserTag.id) {
            duplicated = true;
            break;
          }
        }
        if (!duplicated) {
          this.user.tags.push(supportedUserTag);
        }
        this.setUserTags();
      }
      this.refreshSupportedUserTagsState();
    },
    refreshSupportedUserTagsState() {
      for (let supportedUserTag of this.supportedUserTags) {
        let isSelected = false;
        if (this.user) {
          for (let userTag of this.user.tags) {
            if (userTag.id === supportedUserTag.id) {
              isSelected = true;
              break;
            }
          }
        }
        supportedUserTag.__selected = isSelected;
      }
    },
    setUserTags() {
      if (this.setUserTagsTimeoutId) {
        clearTimeout(this.setUserTagsTimeoutId);
      }
      let userId = this.user.user.id;
      let organizationId = this.organizationId;
      let userTagIds = this.user.tags.map(x => x.id);
      this.setUserTagsTimeoutId = setTimeout(() => {
        doSetUserTags.bind(this)(userId, organizationId, userTagIds);
      }, 200)
    }
  }
}
</script>

<style scoped>

.user-priority {
  width: 64px;
  height: 28px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

.user-priority:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.user-priority:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.user-priority.user-priority__low {
  color: #98c9a9;
}

.user-priority.user-priority__medium {
  color: #e6a23c;
}

.user-priority.user-priority__high {
  color: #f091a6;
}


.user-priority.user-priority__low.is-active {
  background-color: #98c9a9;
  color: white;
}

.user-priority.user-priority__medium.is-active {
  background-color: #e6a23c;
  color: white;
}

.user-priority.user-priority__high.is-active {
  background-color: #f091a6;
  color: white;
}

.user-tag-item {
  background-color: #f1f1f1;
  color: #7d7c7c;
  padding: 4px 16px;
  border-radius: 100px;
  cursor: pointer;
  user-select: none;
}

.user-tag-item:hover {
  filter: brightness(95%);
}

.user-tag-item.is-active {
  background-color: #f091a6;
  color: white;
  font-weight: bold;
}

.btn-remove {
  opacity: 0;
}

.user-tag-item:hover .btn-remove {
  opacity: 1;
}

</style>